<template>
  <div class="class-list">
    <table-list
      :loading="loading"
      :search-form="searchForm(this)"
      :data="list"
      :title="tableTitle"
      :columns="columns(this)"
      :operates="operates(this)"
      :operates2="operates2(this)"
      :operates3="operates3(this)"
      :pager="pager"
      @search="onSearch"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>
    <goLive ref="goLive"></goLive>
  </div>
</template>
<script>
//表格标题
const tableTitle = '我的直播课'
// 搜索框配置
const searchForm = () => [
  {
    label: '直播编号',
    prop: 'roomLive',
  },
  {
    label: '直播名称',
    prop: 'liveName',
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'roomLive',
    label: '直播编号',
  },
  {
    prop: 'livePic',
    label: '直播封面',
    render: (h, { row }) => {
      return [
        row.livePic
          ? h('img', {
              domProps: { src: row.livePic },
              style: 'height: 50px',
            })
          : null,
      ]
    },
  },
  {
    prop: 'liveName',
    label: '直播名称',
  },
  {
    prop: 'liveStatus',
    label: '直播状态',
    formatter: row => {
      if (row.liveStatus == 0) {
        return '待开始'
      } else if (row.liveStatus == 1) {
        return '当日直播'
      } else if (row.liveStatus == 2) {
        return '直播中'
      } else {
        return '已结束'
      }
    },
  },
  {
    prop: 'startTime',
    label: '直播开始时间',
    width: '200px',
  },
]
// 表格操作按钮配置
const operates = _this => [
  {
    label: '详情',
    method: _this.handleDetail,
  },
]
const operates2 = _this => [
  {
    label: '进入直播间',
    method: _this.handleGoLiveRoom,
  },
]
const operates3 = _this => [
  {
    label: '直播秘钥',
    method: _this.handleSSH,
  },
]
import goLive from '../components/goLive'
import TableList from '@/components/TableList'
import { liveCourse } from '@/api/live'
import to from 'await-to'
export default {
  components: {
    TableList,
    goLive,
  },
  data() {
    return {
      searchForm,
      columns,
      operates,
      operates2,
      operates3,
      tableTitle,
      loading: false,
      list: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      queryParams: {},
      teacherSelect: [],
    }
  },
  created() {
    this.liveCourseData()
  },
  methods: {
    async liveCourseData() {
      this.loading = true
      const { current, size } = this.pager
      const [res, err] = await to(liveCourse({ current, size, ...this.queryParams }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.loading = false
      this.list = res.data.list
      this.pager.total = res.data.total
    },

    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      this.queryParams = queryParams
      this.liveCourseData()
    },
    handleGoLiveRoom(row) {
      this.$router.push({
        path: '/live/livePlayback',
        query: { liveId: row.liveId, orgId: row.orgId, path: 'liveCourse' },
      })
    },
    handleSSH(row) {
      this.$refs.goLive.show(row.liveId)
    },
    async handleDetail(row) {
      this.$router.push({ path: '/live/liveDetail', query: { liveId: row.liveId } })
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.liveCourseData()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.liveCourseData()
    },
  },
}
</script>
<style lang="scss" scoped>
.class-list {
  tr {
    &:last-of-type {
      width: 300px;
    }
  }
  ::v-deep.operate-group {
    width: 33.33%;
    float: left;
    span {
      display: block;
      margin: 0;
    }
  }
}
</style>
